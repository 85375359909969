<template>
    <el-dialog
        :close-on-press-escape="false"
        :close-on-click-modal="false"
        class="main-dialog"
        :title="$t('tos.title')"
        :visible.sync="needsToSignTOS"
        :before-close="handleBeforeClose"
        @close="logOutUser"
    >
        <div class="description">{{ $t('tos.description') }}</div>
        <el-checkbox class="tos-link" v-model="checkbox" @change="handleCheckboxChange" border>
            <div class="cel-color">
                <a target="_blank" :href="tosLink">{{ $t('tos.linkText') }} <i class="el-icon-link"></i></a>
            </div>
        </el-checkbox>

        <span slot="footer" class="dialog-footer">
            <el-button icon="el-icon-check" type="primary" @click="confirmTOS">{{ $t('tos.confirm') }}</el-button>
        </span>
    </el-dialog>
</template>

<script>
import { sync, call, get } from 'vuex-pathify';
import { mapGetters } from 'vuex';
export default {
    data: () => ({
        tosLink: 'https://www.cel.ro/doc/termeni-si-conditii-2021-marketplace.pdf',
        checkbox: false
    }),
    methods: {
        LogOut: call('user/LogOut'),
        ApprovedTOS: call('user/ApproveTOS'),
        handleBeforeClose(done) {
            this.$confirm(this.$t('tos.confirm-exit'), this.$t('general.warning'), {
                confirmButtonText: this.$t('general.yes'),
                cancelButtonText: this.$t('general.no'),
                type: 'warning'
            })
                .then(() => {
                    // Log the fucker out and piss on him to show dominance
                    this.logOutUser();
                })
                .catch(() => {});
        },
        async logOutUser() {
            if (!this.needsToSignTOS) return false;
            this.sbMsg({
                type: 'error',
                message: this.$t('tos.logout-message')
            });
            await this.LogOut();
            this.$router.push({ path: '/login' });
        },
        async confirmTOS() {
            await this.ApprovedTOS();
            this.sbMsg({
                type: 'success',
                message: this.$t('tos.confirm-message')
            });
        },
        handleCheckboxChange(val) {
            console.log(val);
        }
    },
    computed: {
        ...mapGetters({
            needsToSignTOS: 'user/needsToSignTOS'
        }),
        modalWidth() {
            return this.$store.state.app.device === 'mobile' ? '90%' : '35%';
        }
    }
};
</script>

<style scoped>
.main-dialog ::v-deep .el-dialog {
    width: 90%;
    max-width: 600px;
}

.main-dialog ::v-deep .el-dialog__title {
    width: 95%;
    display: block;
    position: relative;
    word-break: break-word;
    font-size: 16px;
    font-weight: 700;
}

.description {
    line-height: 1.5;
    word-break: break-word;
    font-size: 14px;
}

.main-dialog ::v-deep .el-dialog__body {
    padding: 20px;
}

.tos-link {
    margin-top: 10px;
}

.el-icon-link {
    font-size: 1.2em;
    vertical-align: middle;
}
</style>
